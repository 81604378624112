<template>
  <div class="content">
    <van-cell-group>
      <div class="top">
        <span>我的头像</span>
        <van-uploader :after-read="afterRead">
          <img v-if="data.img==='null'?false:true" :src="data.img" alt />
          <img v-else class="headimg" src="@/assets/image/默认.png" alt />
        </van-uploader>
      </div>
      <van-cell-group>
        <van-field v-model="data.username" label="我的姓名" placeholder="请输入用户名" />
        <!-- <van-field v-model="data.phone" readonly label="手机号" link /> -->
        <van-cell title="手机号" @click="goUpphone" is-link :value="data.phone" />
        <van-field v-model="data.companyName" readonly label="单位/公司" />
      </van-cell-group>
      <van-cell is-link @click="goPassword">
        <template #title>
          <span class="custom-title">修改我的登录密码</span>
        </template>
      </van-cell>
      <van-cell is-link @click="gologin">
        <template #title>
          <span class="custom-title">退出登录</span>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="btn" @click="upUserInfo">保存信息</div>
  </div>
</template>

<script>
import { updateUserInfo, file } from '@/api/user'
import { Toast } from 'vant'
import axios from 'axios'
export default {
  data() {
    return {
      value: '',
      data: {
        img: ''
      }
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    gologin() {
      Toast('退出成功,请重新登录')
      setTimeout(() => {
        this.$router.push('/login')
        localStorage.setItem('token', '')
      }, 1000)
    },
    async afterRead(a) {
      const { data } = await file({
        fileName: a.file.name
      })
      data.data.body.file = a.file
      const formData = new FormData()
      for (const key in data.data.body) {
        formData.append(key, data.data.body[key])
      }
      axios({
        method: 'post',
        url: data.data.post,
        data: formData
      }).then(res => {
        if (res.status === 200) {
          this.data.img = data.data.url
        }
      })
    },
    async upUserInfo() {
      if (this.data.username) {
        if (this.data.img && this.data.img !== 'null') {
          const { data } = await updateUserInfo({
            username: this.data.username,
            avatar: this.data.img
          })
          if (data.code === 200) {
            Toast.success('修改成功')
            setTimeout(() => {
              this.$router.push('/appointment')
            }, 1000)
          }
        } else {
          const { data } = await updateUserInfo({
            username: this.data.username
          })
          if (data.code === 200) {
            Toast.success('修改成功')
            setTimeout(() => {
              this.$router.push('/appointment')
            }, 1000)
          }
        }
      } else {
        Toast.fail('请输入用户姓名')
      }
    },
    getdata() {
      this.data = this.$route.query
    },
    goPassword() {
      const phone = this.data.phone
      this.$router.push(`/password?phone=${phone}`)
    },
    goUpphone() {
      const phone = this.data.phone
      this.$router.push(`/upphone?phone=${phone}`)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  /deep/ .van-cell {
    color: #646566;
  }
  /deep/ .van-cell__value {
    color: #323233;
  }
  width: 100%;
  height: 100vh;
  /deep/.van-field__control {
    text-align: right;
  }
  .btn {
    width: 80%;
    margin-left: 10%;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    background-color: #f8892f;
    border-radius: 22px;
    text-align: center;
    margin-top: 40px;
    border: none;
    outline: none;
  }
  .custom-title {
    color: #f8892f;
  }
  img {
    width: 63px;
    height: 63px;
    border-radius: 50% 50%;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #646566;
    position: relative;
  }
  .top::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
}
</style>
